import React, {lazy, useEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";

import "../src/assets/sass/style.scss";

import {useCart} from "./app/context/CartContext";
import {AuthInit} from "./app/context/AuthContext";
import MaintenanceMode from "./app/components/MaintenanceMode";

const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

const Header = lazy(() => import('./app/layout/Header'));
const Footer = lazy(() => import('./app/layout/Footer'));
const Newsletter = lazy(() => import('./app/layout/Newsletter'));
const SocialLinks = lazy(() => import('./app/layout/SocialLinks'));

const App = () => {
    const location = useLocation();
    const {cartState} = useCart();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
            localStorage.setItem('code', code);
        }
    }, []);


    useEffect(() => {
        // Function to get the last clear timestamp from local storage
        const getLastClearTimestamp = (): number | null => {
            const storedTimestamp = localStorage.getItem('lastClearTimestamp');
            return storedTimestamp ? parseInt(storedTimestamp, 10) : null;
        };

        // Function to set the current timestamp in local storage
        const setLastClearTimestamp = () => {
            const currentTimestamp = new Date().getTime();
            localStorage.setItem('lastClearTimestamp', currentTimestamp.toString());
        };

        // Function to clear local session storage
        const clearSessionStorage = () => {
            sessionStorage.clear();
            localStorage.clear()
            setLastClearTimestamp(); // Update the last clear timestamp
        };

        // Check last clear timestamp and clear session storage if more than 1 hour
        const lastClearTimestamp = getLastClearTimestamp();
        const hourInMilliseconds = 30 * 60 * 1000; // 1 hour in milliseconds

        if (!lastClearTimestamp || new Date().getTime() - lastClearTimestamp > hourInMilliseconds) {
            clearSessionStorage();
        }

    }, []); // Empty dependency array ensures the effect runs only once on mount
    useEffect(() => {
        window.scrollTo(0, 0);
        // Function to clear local session storage
        const clearSessionStorage = () => {
            sessionStorage.clear();
            localStorage.clear()

        };

        // Set interval to clear session storage every 1 hour (3600000 milliseconds)
        const intervalId = setInterval(clearSessionStorage, 3600000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [location]);

    return (
        <>
            {
                isMaintenanceMode ?
                    <MaintenanceMode/> :
                    <AuthInit>
                        <Header products={cartState.items}/>
                        <SocialLinks className={'sticky-toolbar nav flex-column pt-1 pb-1'}/>
                        <Outlet/>

                        {location.pathname !== '/contact' &&
                            location.pathname !== '/purchase' &&
                            location.pathname !== '/search' &&
                            !location.pathname.includes('partner') &&
                            <Newsletter/>
                        }
                        {/*{*/}
                        {/*    (!process.env.REACT_APP_ENV ||*/}
                        {/*        process.env.REACT_APP_ENV === 'local' ||*/}
                        {/*        process.env.REACT_APP_ENV === 'staging'*/}
                        {/*    ) &&*/}
                        {/*    <PreviewPanel/>*/}
                        {/*}*/}
                        <Footer/>
                    </AuthInit>
            }
        </>
    );
};

export default App;

