import Swal from 'sweetalert2';

export const showAlert = (title: string, text: string, icon?: 'success' | 'error' | 'warning' | 'info' | 'question' | undefined, titleClass: string = '',
                          textClass: string = '') => {
    const swalOptions: any = {
        title,
        text,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
            title: titleClass,
            htmlContainer: textClass
        }
    };

    if (icon) {
        swalOptions.icon = icon;
    }

    Swal.fire(swalOptions);
};
