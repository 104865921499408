import {FormikProps} from "formik";
import React, {Dispatch} from "react";

export const createFormData = (form: any) => {
    let formData = new FormData()

    for (const key in form) {
        insertInForm(formData, key, form[key])
    }

    return formData
}

export const insertInForm = (formData: FormData, formDataKey: string, formItem: any) => {
    if (formItem !== null && formItem !== undefined && formItem !== '') {
        if (formItem instanceof Array) {
            if (formItem.length > 0) {
                for (const itemKey in formItem) {
                    if (
                        formItem[itemKey] instanceof Object &&
                        !(formItem[itemKey] instanceof File) &&
                        !(formItem[itemKey] instanceof Date)
                    ) {
                        Object.keys(formItem[itemKey]).forEach((key: string) => {
                            insertInForm(formData, `${formDataKey}[${itemKey}][${key}]`, formItem[itemKey][key])
                        })
                    } else {
                        formData.append(`${formDataKey}[]`, formItem[itemKey])
                    }
                }
            }
        } else {
            formData.append(formDataKey, formItem)
        }
    }
}

export const genericHandleSingleFile = (
    e: any,
    formik: FormikProps<any>,
    form: any,
    setForm: Dispatch<React.SetStateAction<any>>,
    key: string
) => {
    let file = e.target.files[0]

    setForm({...form, [key]: file})

    formik.setFieldValue(key, file)
}