import React, {useEffect} from "react";
import MainTitle from "./text/MainTitle";
import MainText from "./text/MainText";

const MaintenanceMode = () => {
    useEffect(() => {
        document.body.classList.add('maintenance-mode');

        return () => {
            document.body.classList.remove('maintenance-mode');
        };
    }, []);

    return (
        <div className={'container d-flex align-items-center position-relative text-center'} style={{minHeight: "100vh"}}>
            <div className={'row justify-content-center align-items-center'}>
                <div className={'col-lg-9 col-md-11 col-12'}>
                    <div className={'logo mb-4'}>
                        <img src={"/assets/images/logos/logo.png"} alt="Garden  of Life" className="logo"/>
                    </div>

                    <MainTitle className={`main-title pt-5 pb-3`}
                               text={'We have something new special in store for you,'}/>
                    <h6 className={'title-h6 pb-4'}>And we can not wait <br/> for you to see!</h6>
                    <MainText className={'main-text'} text={'Check Back Soon!'}/>
                    <p className={'main-p text-color text-uppercase position-absolute bottom-5'}
                       style={{left: '50%', transform: 'translateX(-50%)'}}>
                        - Ongoing Maintenance -
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MaintenanceMode;

