import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {Partner} from "../../models/Partner";
import {getPartners} from "../../core/partner/requests";
import {useLanguage} from "../../context/LanguageContext";
import {filterSectionsContentBySlug} from "./core/helpers";
import {SectionContent} from "./core/models";
import {t} from "i18next";

interface PartnerContextType {
    partners: Partner[] | undefined;
    setPartners: React.Dispatch<React.SetStateAction<Partner[] | undefined>>;
    getSectionContent: (type: string) => SectionContent | undefined;
}

const PartnerContext = createContext<PartnerContextType | undefined>(undefined);

export const PartnerProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [partners, setPartners] = useState<Partner[] | undefined>(undefined);
    const {currentLanguageCode} = useLanguage()

    const sectionsContent: SectionContent[] = [
        {
            title: t('partners.business.title'),
            type: "business",
            description: t('partners.business.description'),
            singleTitle : t('partners.business.singleTitle'),
            singleDescription: t('partners.business.singleDescription')
        },
        {
            title: t('partners.charity.title'),
            type: "charity",
            description: t('partners.charity.description'),
            singleTitle : t('partners.charity.singleTitle'),
            singleDescription: t('partners.charity.singleDescription')
        },
        {
            title: t('partners.merchant.title'),
            type: "campaign",
            description: t('partners.merchant.description'),
            singleTitle : t('partners.merchant.singleTitle'),
            singleDescription: t('partners.merchant.singleDescription')
        },
        {
            title: t('partners.merchant.title'),
            type: "merchant",
            description: t('partners.merchant.description'),
            singleTitle : t('partners.merchant.singleTitle'),
            singleDescription: t('partners.merchant.singleDescription')
        }
    ];

    useEffect(() => {
        getPartners(currentLanguageCode).then((response) => {
            setPartners(response.data)
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSectionContent = (type: string): SectionContent | undefined => {
        return filterSectionsContentBySlug(sectionsContent, type);
    }

    return (
        <PartnerContext.Provider
            value={{
                partners, setPartners, getSectionContent
            }}>
            {children}
        </PartnerContext.Provider>
    );
};

export const usePartner = (): PartnerContextType => {
    const context = useContext(PartnerContext);
    if (!context) {
        throw new Error('usePartner must be used within a CartProvider');
    }
    return context;
};
