/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, lazy, Suspense} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import translationsEn from "../locales/en/translation.json";
import translationsId from "../locales/id/translation.json";
import translationsDe from "../locales/de/translation.json";
import {PartnerProvider} from "../sections/partner/PartnerContext";
import App from "../../App";


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

// const App = lazy(() => import('../../App'));
const MetaverseIndex = lazy(() => import('../sections/metaverse/MetaverseIndex'));
const ReferralLink = lazy(() => import('../sections/referral/ReferralLink'));
const BlogIndex = lazy(() => import('../sections/blog/BlogIndex'));
const BlogShow = lazy(() => import('../sections/blog/BlogShow'));
const CharityIndex = lazy(() => import('../sections/charity/CharityIndex'));
const ProjectIndex = lazy(() => import('../sections/projects/ProjectIndex'));
const Shop = lazy(() => import('../sections/shop/Shop'));
const ProductShow = lazy(() => import('../sections/shop/ProductShow'));
const ProjectShow = lazy(() => import('../sections/projects/ProjectShow'));
const About = lazy(() => import('../sections/pages/about/About'));
const Home = lazy(() => import('../sections/home/Home'));
const Influencer = lazy(() => import('../sections/influencer/Influencer'));
const Contact = lazy(() => import('../sections/pages/contact/Contact'));
const FAQs = lazy(() => import('../sections/pages/faq/Faq'));
const PartnerShow = lazy(() => import('../sections/partner/Show'));
const Cart = lazy(() => import('../sections/cart/Cart'));
const PartnerIndex = lazy(() => import('../sections/partner/Index'));
const Payment = lazy(() => import('../sections/cart/Payment'));
const Terms = lazy(() => import('../sections/pages/Terms'));
const Privacy = lazy(() => import('../sections/pages/Privacy'));
const CookiePolicy = lazy(() => import('../sections/pages/CookiePolicy'));
const IFAPartner = lazy(() => import('../sections/partners/IfaPage'));
const Support = lazy(() => import('../sections/pages/Support'));
const IcelandMetaverse = lazy(() => import('../sections/metaverse/iceland'));
const BaliMetaverse = lazy(() => import('../sections/metaverse/bali'));
const BrazilMetaverse = lazy(() => import('../sections/metaverse/brazil'));
const BotswanaMetaverse = lazy(() => import('../sections/metaverse/botswana'));
const UAEMetaverse = lazy(() => import('../sections/metaverse/uae'));
const BlogTagIndex = lazy(() => import('../sections/blog/BlogTagIndex'));
const Search = lazy(() => import('../components/search/Search'));


i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        resources: {
            en: {translation: translationsEn},
            de: {translation: translationsDe},
            id: {translation: translationsId}
        },
        supportedLngs: ["en", "de", 'id'],
        fallbackLng: "en",
        detection: {
            order: ["path", "cookie", "htmlTag"],
            caches: ["cookie"],
        },

        preload: ["en", "de", "id"],
    });
const AppRoutes: FC = () => {
    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Suspense>
                <Routes>
                    <Route element={<App/>}>
                        {/* Home */}
                        <Route path="/en" element={<Home/>}/>
                        <Route path="/de" element={<Home/>}/>
                        <Route path="/id" element={<Home/>}/>
                        <Route path="/" element={<Home/>}/>

                        {/* Metaverse Routes */}
                        <Route path="/:lang?/metaverse" element={<MetaverseIndex/>}/>
                        <Route path="/:lang?/project/bali-metaverse" element={<BaliMetaverse/>}/>
                        <Route path="/:lang?/project/brazil-metaverse" element={<BrazilMetaverse/>}/>
                        <Route path="/:lang?/project/botswana-metaverse" element={<BotswanaMetaverse/>}/>
                        <Route path="/:lang?/project/united-arab-emirates-metaverse" element={<UAEMetaverse/>}/>
                        <Route path="/:lang?/project/iceland-metaverse" element={<IcelandMetaverse/>}/>

                        {/* Static Pages */}
                        <Route path="/:lang?/contact" element={<Contact/>}/>
                        <Route path="/:lang?/faqs" element={<FAQs/>}/>
                        <Route path="/:lang?/about" element={<About/>}/>
                        <Route path="/:lang?/terms-and-conditions" element={<Terms/>}/>
                        <Route path="/:lang?/privacy-policy" element={<Privacy/>}/>
                        <Route path="/:lang?/cookie-policy" element={<CookiePolicy/>}/>
                        <Route path="/:lang?/support" element={<Support/>}/>
                        <Route path='/:lang?/search' element={<Search/>}/>

                        {/* Blog Routes */}
                        <Route path="/:lang?/blogs" element={<BlogIndex/>}/>
                        <Route path="/:lang?/blog/:slug" element={<BlogShow/>}/>
                        <Route path="/:lang?/blog/tag/:slug" element={<BlogTagIndex/>}/>

                        {/* Charity */}
                        <Route path="/:lang?/charity" element={<CharityIndex/>}/>

                        {/* Projects */}
                        <Route path="/:lang?/projects" element={<ProjectIndex/>}/>
                        <Route path="/:lang?/project/:project" element={<ProjectShow/>}/>
                        <Route path="/:lang?/project/:project/:product" element={<ProductShow/>}/>
                        <Route path="/:lang?/project/:project/:product/page" element={<ProductShow/>}/>

                        {/* Shop */}
                        <Route path="/:lang?/shop" element={<Shop/>}/>

                        {/* Payment */}
                        <Route path="/:lang?/payment/:status" element={<Payment/>}/>
                        <Route path="/:lang?/referral-link" element={<ReferralLink/>}/>

                        {/* IFA Partner */}
                        <Route path="/:lang?/ifa-twin-trees" element={<IFAPartner/>}/>

                        {/* Influencer */}
                        <Route path="/:lang?/:slug" element={<Influencer/>}/>

                        {/* Partner */}
                        <Route path='/:lang?/partners' element={<PartnerProvider><PartnerIndex/></PartnerProvider>}/>
                        <Route path='/:lang?/partners/campaign' element={<PartnerProvider><PartnerIndex toCampaignSection={true}/></PartnerProvider>}/>
                        <Route path='/:lang?/partner/:slug'
                               element={<PartnerProvider><PartnerShow/></PartnerProvider>}/>

                        {/* Cart */}
                        <Route path='/:lang?/purchase' element={<Cart/>}/>

                        {/* Default Route */}
                        <Route path="*" element={<Navigate to="/error/404"/>}/>

                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

export {AppRoutes}
