import React from "react";
import reportWebVitals from "./reportWebVitals";
import {createRoot} from "react-dom/client";
import {LanguageProvider} from "./app/context/LanguageContext";
import {AppRoutes} from "./app/routing/AppRoutes";
import {CartProvider} from "./app/context/CartContext";
import {AuthProvider} from "./app/context/AuthContext";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <LanguageProvider>
            <AuthProvider>
                <CartProvider>
                    <AppRoutes/>
                </CartProvider>
            </AuthProvider>
        </LanguageProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
